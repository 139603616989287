<template>
  <div class="main_box">
    <div class="title">行业动态——{{ titleObj[type - 1].title }}</div>
    <ul class="listUl" v-loading="loading">
      <li @click="handelGo(item.id)" v-for="(item, index) in list" :key="index">
        <span class="name">
          {{ item.title }}
        </span>
        <span class="time">{{ parseTime(item.createdAt) }}</span>
      </li>
    </ul>
    <div class="pageBtnCon">
      <el-pagination @current-change="handleCurrentChange" :current-page.sync="page" background :page-size="limit"
        layout="prev, pager, next, jumper" :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { APPID, TRAVEL, HOUSE, DESIGN, FINANCE, CARS, HEALTH, FASHION } from '@/utils/api/config'
export default {
  data() {
    return {
      type: 1,
      titleObj: [{
        title: '旅游',
        channelId: TRAVEL
      }, {
        title: '房产',
        channelId: HOUSE
      }, {
        title: '家装',
        channelId: DESIGN
      }, {
        title: '财经',
        channelId: FINANCE
      }, {
        title: '汽车',
        channelId: CARS
      }, {
        title: '健康',
        channelId: HEALTH
      }, {
        title: '时尚',
        channelId: FASHION
      }],
      page: 1,
      limit: 12,
      total: 0,
      list: [],
      loading: true
    };
  },
  methods: {
    handelGo(id) {
      // this.$router.push({ path: "/activeDetail/" + id });
      let { href } = this.$router.resolve({ path: "/activeDetail/" + id })
      window.open(href, '_blank')
    },
    getList() {
      var key = this.titleObj[this.type - 1].channelId
      this.axios.get(`api/fusion/pc/news/list?appId=${APPID}&channelId=${key}&page=${this.page}&limit=${this.limit}`, {}).then(res => {
        if (res.code !== '200') {
          this.$message.error(res.msg)
          return
        }
        this.list = res.data.list || []
        this.total = res.data.count
        this.loading = false
      })
    },
    handleCurrentChange(val) {
      this.page = val
      this.getList()
    },
  },
  mounted() {

  },
  watch: {
    "$route.query.type": {
      handler(newVal, oldVal) {
        // 当路由参数发生变化时执行的函数
        console.log("路由参数发生变化", newVal);
        this.type = newVal;
        this.getList()
      },
      deep: true, // 深度监听
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.main_box {
  padding: 30px 0px 0;

  .title {
    margin-bottom: 30px;
    font-size: 22px;
    font-weight: 600;
  }

  .listUl {
    min-height: 684px;
  }

  ul {
    // min-height: calc(100vh - 490px);
    margin: 1em 0;

    li {
      font-size: 18px;
      height: 55px;
      line-height: 55px;
      position: relative;
      cursor: pointer;

      .name {
        display: inline-block;
        width: 660px;
        margin-left: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .time {
        font-size: 14px;
        color: #bfbfbf;
        float: right;
      }
    }

    li::before {
      content: "";
      display: inline-block;
      position: absolute;
      top: 25px;
      width: 5px;
      height: 5px;
      background-color: #bfbfbf;
      /* 设置圆点的颜色 */
    }
  }

  .page {
    display: flex;
    justify-content: center;
  }
}

.pageBtnCon {
  width: 100%;
  height: 120px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>